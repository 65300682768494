import React, { useState, useEffect } from 'react';
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from 'react-router-dom';
import TeamTwo from "../components/team/TeamTwo";
import FooterOne from "../components/footer/FooterOne";

import Accordion from 'react-bootstrap/Accordion';



function OurService() {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'About Us' }
    ];
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    // Function to open the video overlay
    const openVideo = (e) => {
        e.preventDefault();
        setIsVideoOpen(true);
    };

    // Function to close the video overlay
    const closeVideo = (e) => {
        e.preventDefault();
        setIsVideoOpen(false);
    };

    // Effect to handle the escape key for closing the video overlay
    useEffect(() => {
        const handleKeyUp = (e) => {
            if (e.keyCode === 27) {
                setIsVideoOpen(false);
            }
        };

        // Add event listener for keyup
        document.addEventListener('keyup', handleKeyUp);

        // Cleanup function to remove event listener on component unmount
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);
    return (
        <div className=''>

            <HeaderOne />
            <Breadcrumb title="About Us" breadcrumbs={breadcrumbs} />
            {/* rts about us section start */}
            <div className="rts-about-area rts-section-gap">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-image-v-inner">
                                <div className="image-area">
                                    <img
                                        className="mt--110 img-1"
                                        src="assets/images/about/main/about-03.jpg"
                                        alt="BUsiness_image"
                                    />
                                    <img
                                        className="img-over"
                                        src="assets/images/about/main/about-04.jpg"
                                        alt="BUsiness_image"
                                    />
                                    <div className="goal-button-wrapper">
                                        <Link to={'#'} className="vedio-icone">
                                            <span id="play-video" className="video-play-button" onClick={openVideo}>
                                                {/* <img
                                                    src="assets/images/about/shape/play-btn.png"
                                                    alt="Play btn"
                                                /> */}
                                                <span />
                                            </span>
                                            {isVideoOpen && (
                                                <div id="video-overlay" className="video-overlay open">
                                                    {/* Close button for the video overlay */}
                                                    <Link className="video-overlay-close" to={'#'} onClick={closeVideo}>
                                                        ×
                                                    </Link>
                                                    {/* Video iframe */}
                                                    <iframe
                                                        width="560"
                                                        height="315"
                                                        src="https://www.youtube.com/embed/6stlCkUDG_s"
                                                        title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            )}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-progress-inner">
                                <div className="title-area">
                                    <h2 className="title">
                                        Who are we?
                                    </h2>
                                </div>
                                {/* inner start */}
                                <div className="inner" style={{ textAlign: 'justify' }}>
                                    <p className="disc">
                                        Cogency Group is a highly reputable company with extensive experience in
                                        Telecommunications and Information Technology (IT). With over 25 years of expertise and
                                        more than 10 years in operations, the company supports Multi-Service Operators (MSOs) in
                                        managing and implementing FTTH metropolitan network projects, and Internet Service
                                        Providers (ISPs) in managing and implementing FTTP and FTTB projects alongside
                                        Structure Cabling. Additionally, Cogency Group offers Tier 1 & 2 helpdesks for ISPs'
                                        Network Operations Centers (NOC) and Security Operations Centers (SOC), routing and
                                        switching solutions, cybersecurity support, network management, and telecommunications
                                        network design.
                                    </p>
                                    <p className="disc">
                                        Cogency Group has established itself as a B2B boutique service provider for MSO and ISP
                                        enterprises, catering to clients in various industries, including Hospitality, Education,
                                        Telecommunications, Warehouse/Retail, and Banking. As a B2B service provider, we understand the criticality of remaining at the forefront of the
                                        constantly evolving technological landscape. To ensure this, we have meticulously curated
                                        a team of top-tier professionals renowned for their expertise and knowledge. Our engineers
                                        possess the most esteemed and intricate ICT certifications in the market, including RDCC,
                                        CISSP, CCNP, among others. We are committed to providing cutting-edge solutions alongside an Excellent Customer
                                        Experience that are not only reliable and efficient but also cost-effective. Our goal is to
                                        propel business growth and elevate customer satisfaction through our unwavering
                                        dedication to excellence and innovation.
                                    </p>
                                    <Link to={'#'} className="rts-btn btn-primary">
                                        Make an Appointment
                                    </Link>
                                </div>
                                {/* end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rts about us section end */}

            {/* rts about us section start */}
            <div className="rts-about-area rts-section-gap">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-progress-inner">
                                <div className="title-area">
                                    <h2 className="title">
                                        Why do we do what we do?
                                    </h2>
                                </div>
                                {/* inner start */}
                                <div className="inner" style={{ textAlign: 'justify' }}>
                                    <p className="disc">
                                        <strong><u>Our Mission</u></strong> is to become a trusted partner for our clients, offering a Business Process
                                        Outsourcing (BPO) experience combined with reliable solutions for their most sensitive
                                        services, encompassing the construction, management, and maintenance of Information
                                        and Communication Technology (ICT) infrastructures.
                                    </p>
                                    <p className="disc">
                                        <strong><u>Our Vision</u></strong> is to create a world where digital inclusion is a reality for everyone, regardless
                                        of their location or socioeconomic status. We will achieve this by working with
                                        telecommunications and information technology corporations to develop cost-effective and
                                        high-quality infrastructures that will provide everyone with easy, safe, and cheaper access
                                        to technological resources via ISPs.
                                    </p>
                                    <p>
                                        <strong><u>Our Values</u></strong>
                                        <div className="fmba-two-columns-container">
                                            <div className="single-project-details-challenge">
                                                <div className="icon">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                                <p className="details">
                                                    Passion
                                                </p>
                                            </div>
                                            <div className="single-project-details-challenge">
                                                <div className="icon">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                                <p className="details">
                                                    Quality
                                                </p>
                                            </div>
                                            <div className="single-project-details-challenge">
                                                <div className="icon">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                                <p className="details">
                                                    Honesty
                                                </p>
                                            </div>
                                            <div className="single-project-details-challenge">
                                                <div className="icon">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                                <p className="details">
                                                    Simplicity
                                                </p>
                                            </div>
                                            <div className="single-project-details-challenge">
                                                <div className="icon">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                                <p className="details">
                                                    Warmth
                                                </p>
                                            </div>
                                            <div className="single-project-details-challenge">
                                                <div className="icon">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                                <p className="details">
                                                    Responsibility
                                                </p>
                                            </div>
                                            <div className="single-project-details-challenge">
                                                <div className="icon">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                                <p className="details">
                                                    Punctuality
                                                </p>
                                            </div>
                                            <div className="single-project-details-challenge">
                                                <div className="icon">
                                                    <i className="far fa-check-circle" />
                                                </div>
                                                <p className="details">
                                                    Commitment
                                                </p>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                {/* end */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-image-v-inner">
                                <div className="image-area">
                                    <img
                                        className="mt--110 img-1"
                                        src="assets/images/about/main/about-03.jpg"
                                        alt="BUsiness_image"
                                    />
                                    <img
                                        className="img-over"
                                        src="assets/images/about/main/about-04.jpg"
                                        alt="BUsiness_image"
                                    />
                                    <div className="goal-button-wrapper">
                                        <Link to={'#'} className="vedio-icone">
                                            <span id="play-video" className="video-play-button" onClick={openVideo}>
                                                {/* <img
                                                    src="assets/images/about/shape/play-btn.png"
                                                    alt="Play btn"
                                                /> */}
                                                <span />
                                            </span>
                                            {isVideoOpen && (
                                                <div id="video-overlay" className="video-overlay open">
                                                    {/* Close button for the video overlay */}
                                                    <Link className="video-overlay-close" to={'#'} onClick={closeVideo}>
                                                        ×
                                                    </Link>
                                                    {/* Video iframe */}
                                                    <iframe
                                                        width="560"
                                                        height="315"
                                                        src="https://www.youtube.com/embed/6stlCkUDG_s"
                                                        title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            )}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rts about us section end */}

            <TeamTwo />

            {/* customers feed back area start */}
            <div className="rts-customer-feedback-area rts-section-gap bg-customer-feedback">
                <div className="container">
                    <div className="row">
                        <div className="rts-title-area feedback team text-center">
                            <p className="pre-title">Feedbacks</p>
                            <h2 className="title">Customer Feedbacks</h2>
                        </div>
                    </div>
                    <div className="row g-5 mt--20">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="testimopnial-wrapper-two">
                                <div className="test-header">
                                    <div className="thumbnail">
                                        <img src="assets/images/testimonials/02.png" alt="" />
                                    </div>
                                    <div className="name-desig">
                                        <h5 className="title">David Smith</h5>
                                        <span className="designation">Business Expert</span>
                                    </div>
                                </div>
                                <div className="test-body">
                                    <p className="disc">
                                        “Parallel task user friendly convergence through supply are
                                        chains type siflify reliable meta provide service visionary
                                        sources unleash tactical thinking via granular intellectual
                                        capital architect dynamic information value online business
                                        solution services”
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="testimopnial-wrapper-two">
                                <div className="test-header">
                                    <div className="thumbnail">
                                        <img src="assets/images/testimonials/03.png" alt="" />
                                    </div>
                                    <div className="name-desig">
                                        <h5 className="title">David Smith</h5>
                                        <span className="designation">Business Expert</span>
                                    </div>
                                </div>
                                <div className="test-body">
                                    <p className="disc">
                                        “Parallel task user friendly convergence through supply are
                                        chains type siflify reliable meta provide service visionary
                                        sources unleash tactical thinking via granular intellectual
                                        capital architect dynamic information value online business
                                        solution services”
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* customers feed back area end */}


            <FooterOne />

        </div>
    )
}

export default OurService