import React from 'react'

function BrandOne() {
    return (
        <div>
            {/* start trusted client section */}
            <div className="rts-trusted-client rts-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title-area-client text-center">
                                <p className="client-title">Our Trusted Clients</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="client-wrapper-one">
                        {Array.from({ length: 18 }, (_, i) => i + 1).map((item) => (
                                <a href="#">
                                    <img src={`assets/images/client/${item}.webp`} alt="business_finbiz" />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* end trusted client section */}

        </div>
    )
}

export default BrandOne